import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from "../views/Layout.vue";
import RouterViewTemplate from "../components/RouterViewTemplate.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "index",
        component: () => import("../views/IndexView.vue")
      },
      {
        path: "solution",
        name: "solution",
        component: RouterViewTemplate,
        children: [
          {
            path: "m-require",
            name: "m-require-solution",
            component: () => import("../views/Solution/MRequireView.vue")
          },
          {
            path: "m-design",
            name: "m-design-solution",
            component: () => import("../views/Solution/MDesignView.vue")
          },
          {
            path: "m-sim",
            name: "m-sim-solution",
            component: () => import("../views/Solution/MSimView.vue")
          }
        ]
      },
      {
        path: "case",
        name: "case",
        component: RouterViewTemplate,
        children: [
          {
            path: "rail",
            name: "rail",
            component: () => import("../views/Case/RailView.vue")
          },
          {
            path: "aerospace",
            name: "aerospace",
            component: () => import("../views/Case/AerospaceView.vue")
          },
          {
            path: "nuclear",
            name: "nuclear",
            component: () => import("../views/Case/NuclearView.vue")
          },
          {
            path: "weapon",
            name: "weapon",
            component: () => import("../views/Case/WeaponView.vue")
          }
        ]
      },
      {
        path: "product",
        name: "product",
        component: RouterViewTemplate,
        children: [
          {
            path: "m-design",
            name: "m-design",
            component: () => import("../views/Product/ProductMDView.vue")
          },
          {
            path: "m-cowork",
            name: "m-cowork",
            component: () => import("../views/Product/ProductMCView.vue")
          },
          {
            path: "m-simwork",
            name: "m-simwork",
            component: () => import("../views/Product/ProductMSView.vue")
          },
          {
            path: "m-require",
            name: "m-require",
            component: () => import("../views/Product/ProductMRView.vue")
          },
          {
            path: 'm-arch',
            name: 'm-arch',
            component: () => import("../views/Product/ProductMArchView.vue")
          },
          {
            path: 'm-plm',
            name: 'm-plm',
            component: () => import("../views/Product/ProductPLMView.vue")
          },
        ]
      },
      {
        path: "support",
        name: "support",
        component: RouterViewTemplate,
        children: [
          {
            path: "helpcenter",
            name: "helpcenter",
            component: () => import("../views/Support/HelpCenterView.vue")
          },
          {
            path: "information",
            name: "information",
            component: () => import("../views/Support/InformationView.vue")
          },
          {
            path: "consult/1",
            name: "innovate",
            component: () => import("../views/Support/ConsultInnovateView.vue")
          },
          {
            path: "consult/2",
            name: "mbse",
            component: () => import("../views/Support/ConsultMBSEView.vue")
          },
          {
            path: "consult/3",
            name: "cooperation",
            component: () => import("../views/Support/ConsultCooperationView.vue")
          },
          {
            path: "consult/4",
            name: "endeavor",
            component: () => import("../views/Support/ConsultEndeavorView.vue")
          }
        ]
      },
      {
        path: "about",
        name: "about",
        component: RouterViewTemplate,
        children: [
          {
            path: "recruit",
            name: "recruit",
            component: () => import("../views/About/RecruitView.vue")
          },
          {
            path: "expertteam",
            name: "expertteam",
            component: () => import("../views/About/ExpertView.vue")
          },
          {
            path: "concerning",
            name: "aboutConcerning",
            component: () => import("../views/About/AboutTabView.vue"),
            redirect: "concerning/concerning",
            children: [
              {
                path: 'concerning',
                name: 'concerning',
                component: () => import("../views/About/ConcerningView.vue")
              },
              {
                path: 'contact',
                name: 'aboutContact',
                component: () => import("../views/About/ContactView.vue")
              },
              {
                path: 'join',
                name: 'join',
                component: () => import("../views/About/JoinView.vue"),
                redirect: "join/socity",
                children: [
                  {
                    path: 'socity',
                    name: 'socity',
                    component: () => import("@/components/about/PositionScreeningCard.vue")
                  },
                  {
                    path: 'campus',
                    name: 'campus',
                    component: () => import("@/components/about/PositionScreeningCardToCampus.vue")
                  }
                ]
              }
            ]
          },
          {
            path: "positiondetail",
            name: "positiondetail",
            component: () => import("../views/About/PositionDetailView.vue")
          },
          {
            path: "positiondetailtocampus",
            name: "positiondetailtocampus",
            component: () => import("../views/About/PositionDetailViewToCampus.vue")
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: RouterViewTemplate,
    children: [
      {
        path: "contact",
        name: "contact",
        component: () => import("../views/ContactView.vue")
      },
      {
        path: "test2",
        name: "test2",
        component: () => import("../views/test.vue")
      },
    ]
  },
  {
    path: '/XHB',
    name: "XHB",
    component: () => import("../views/XHB/index.vue"),
    redirect: "/XHB/main",
    children: [
      {
        path: "main",
        name: "xhbMain",
        component: () => import("../views/XHB/Main.vue")
      },
      {
        path: "more/case",
        name: "moreCase",
        component: () => import("../views/XHB/More.vue")
      },
      {
        path: "more/viedo",
        name: "moreViedo",
        component: () => import("../views/XHB/More.vue")
      },
      {
        path: 'more/work',
        name: 'moreWork',
        component: () => import('../views/XHB/Work.vue')
      }
    ]
  },
  {
    path: '/education',
    name: "education",
    component: () => import("../views/Education/index.vue"),
    redirect: "/education/main",
    children: [
      {
        path: "main",
        name: "main",
        component: () => import("../views/Education/Main.vue")
      },
      {
        path: "more/case",
        name: "educationMoreCase",
        component: () => import("../views/Education/More.vue")
      },
      {
        path: "more/viedo",
        name: "educationMoreViedo",
        component: () => import("../views/Education/More.vue")
      }
    ]
  },
  {
    path: '/backend',
    name: "内部编辑界面",
    component: () => import("../views/backend/index.vue"),
    redirect: "/backend/articleList",
    children: [
      {
        path: "teamMemberList",
        name: "团队成员列表",
        component: () => import("../views/backend/TeamMemberList.vue")
      },
      {
        path: "articleList",
        name: "文章列表",
        component: () => import("../views/backend/ArticleList.vue")
      },
      {
        path: "articleEditor",
        name: "文章编辑",
        component: () => import("../views/backend/ArticleEditor.vue")
      }
    ]
  },
  {
    path: '/official-account',
    name: "OfficialAccount",
    component: () => import("@/views/OfficialAccount/Layout/Layout.vue"),
    redirect: "/official-account/product",
    children: [
      {
        path: "product",
        name: "OfficialAccountProduct",
        meta: {
          keepAlive: true
        },
        component: () => import("@/views/OfficialAccount/Product/Product.vue")
      },
      {
        path: "detail",
        name: "OfficialAccountProductDetail",
        component: () => import("@/views/OfficialAccount/ProductDetail/ProductDetail.vue")
      }
    ]
  },
  {
    path: '/test',
    name: "test",
    component: () => import("@/views/test.vue")

  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  // safari
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    window.pageYOffset = 0;
  }
  if (to.path.indexOf('/XHB') > -1) {
    document.title = '中核集团"星河"杯系统工程竞赛';
    let icon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    if (icon !== null) {
      icon.href = 'XHB.ico';
    }
  } else {
    document.title = '杭州华望系统科技有限公司';
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
  }
  next();
});
// router.afterEach((to, from) => {
//   setInterval(function(){
//     history.go(0);
//   }, 100000000);
// });
export default router;
