import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/app.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '@/extension/jsExtension';

import BaiduMap from 'vue-baidu-map';
import { settingHtmlFontSizeForRem } from './utils/index';

settingHtmlFontSizeForRem();
Vue.use(BaiduMap, {
  ak: '3E12jIzzCZiGz1H956b09mAxCtxnLbQ1'
});
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { fetch as whatwgFetch } from "whatwg-fetch";

if (!window.fetch) {
  window.fetch = whatwgFetch;
}

Vue.config.productionTip = false;

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
