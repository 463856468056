export function settingHtmlFontSizeForRem (baseSize = 40, baseWidth = 1000) {
  const doc = document;
  const win = window;
  const docEI = doc.documentElement;
  const resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize';
  function resize() {
    const clientWidth = docEI.clientWidth;
    if (!clientWidth) return;
    let calcFontSize = baseSize * (clientWidth / baseWidth);
    calcFontSize = Math.min(calcFontSize, 50);
    calcFontSize = Math.max(20, calcFontSize);
    docEI.style.fontSize = calcFontSize + 'px';
  }

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, resize, false);
  doc.addEventListener('DOMContentLoaded', resize, false);
}